import { load } from 'cheerio';

export const calculateReadingTime = (content) => {
	// Assuming an average reading speed of 200 words per minute
	const wordsPerMinute = 200;

	// Count the number of words in the content
	const wordCount = content.split(/\s+/).length;

	// Calculate the estimated reading time in minutes
	const readingTimeMinutes = wordCount / wordsPerMinute;

	// Round up the reading time to the nearest whole number
	const roundedReadingTime = Math.ceil(readingTimeMinutes);

	return roundedReadingTime;
};

export const imageLinkFormatter = (
	link,
	width = 456,
	quality = 70,
	env,
	hostname
) => {
	if (!link) return;
	if (env === "PRODUCTION")
		if (!link.includes("/cdn-cgi/image/")) {
			const trimmed_link = link.split(".com")[1];

			const img_format = link.includes(".gif") ? "webp" : "webp";

			return `${hostname}/cdn-cgi/image/quality=${quality},format=${img_format},width=${width}/${hostname}${trimmed_link}`;
		}
	return link;
};

export const getLyteWrapperFields = (content) => {
	let lyte_fields = {};

	const $ = load(content, {
		decodeEntities: false, // Prevent HTML entity decoding
		xmlMode: false // Do not treat input as XML
	});

	if ($('.lyMe.hidef')) {
		lyte_fields = {
			el_id: $('.lyMe.hidef')[0].attribs['id'],
			yt_id: $('.lyMe.hidef')[0].children[0].attribs['id'].split('lyte_')[1],

		};
	}

	return lyte_fields;
};

export const imageLinkFormatterForPost = (link) => {
	return imageLinkFormatter(link, 786);
};

export const youtubeEmbedParser = (hostname) => {
	const lyte_wrappers = document.querySelectorAll(".lyte-wrapper");

	if (lyte_wrappers.length > 0) {
		[...lyte_wrappers].forEach(function (el) {
			// WordPress Lyte Wrapper - YouTube

			if (el.hasAttribute("data-parsed")) return;
			el.setAttribute("data-parsed", true);

			const lyte_fields = getLyteWrapperFields(el.innerHTML);

			// if (lyte_fields !== {}) {
			const yt_id = lyte_fields["yt_id"];
			const el_id = lyte_fields["el_id"];

			const yt_iframe =
				'<iframe id="iF_' +
				yt_id +
				'" width="840" height="236" ' +
				'src="https://www.youtube-nocookie.com/embed/' +
				yt_id +
				"?autoplay=1&" +
				'controls=1&wmode=opaque&rel=0&egm=0&iv_load_policy=3&hd=1&vq=hd720" ' +
				'frameborder="0" style="" allowfullscreen="" allow="autoplay"></iframe>';

			document
				.querySelector('[id="' + el_id + '"]')
				.addEventListener("click", function (evt) {
					el.innerHTML = yt_iframe;
				});
			// }
		});
	}

	const allHaveAttribute = Array.from(lyte_wrappers).every((el) =>
		el.hasAttribute("data-foo")
	);

	if (allHaveAttribute) return "close";
};

export const onClickOnAnchorScrollToTitle = (e) => {
	setTimeout(() => {
		const el_y_position = window.scrollY;
		window.scrollTo(0, el_y_position - 100);
	}, 25);
};

export const addTwitterEmbedd = () => {
	const twitterScript = document.createElement("script");
	twitterScript.setAttribute("src", "https://platform.twitter.com/widgets.js");
	twitterScript.setAttribute("defer", true);

	const body = document.querySelector("body");

	body.appendChild(twitterScript);
};

export function modifyAndSubmitForm(emailInputId) {
	const emailInput = document.getElementById(emailInputId);

	if (!emailInput) {
		return false;
	}

	const emailValue = emailInput.value;

	//URL encoding
	const modifiedEmailValue = emailValue.replace(/\+/g, "%2B");

	//Hidden Input
	emailInput.value = modifiedEmailValue;

	return true;
}

export const fetchAuthorByName = async (hostname, authorName) => {
	try {
		const randomParam = Math.random().toString(36).substring(7);

		const response = await fetch(`${hostname}wp-json/biographies/v1/author-metadata/all?random=${randomParam}`, {
			method: "GET"
		});

		if (response.status === 200) {
			const customAuthors = await response.json();
			const author_name = authorName.replace("-", " ");

			const author = customAuthors.find((author) => author.name.toLowerCase() === author_name)

			console.log("0 Author:", author);
			return author;

		} else {
			console.error("Custom authors request failed:", response.statusText);
			return null;
		}
	} catch (error) {
		console.error("Error loading custom authors:", error);
		return null;
	}
};


export const getYoutubeLink = (content) => {
	const $ = load(content, {
		decodeEntities: false, // Prevent HTML entity decoding
		xmlMode: false // Do not treat input as XML
	});

	let ytObj;
	$("div").each((_index, ytDiv) => {
		if ($(ytDiv).attr("class") === "lyte-wrapper") {
			if (!ytObj) {
				const youtubeId = ytDiv.children[0].attribs.id.split('_')[1];

				ytObj = {
					thumbnailUrl: `https://i.ytimg.com/vi/${youtubeId}/hqdefault.jpg`,
					contentUrl: `https://www.youtube.com/watch?v=${youtubeId}"`,
					embedUrl: `https://www.youtube.com/watch?v=${youtubeId}"`
				}

				return null;
			}
		}
	});

	return ytObj;
};

export const external_links_icon = `<svg class="external-icon"  fill="#3b82f6" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h560v-280h80v280q0 33-23.5 56.5T760-120H200Zm188-212-56-56 372-372H560v-80h280v280h-80v-144L388-332Z"/></svg>`;

export const shuffleArray = (array) => {
	for (let i = array.length - 1; i > 0; i--) {
		// Generate random index
		const j = Math.floor(Math.random() * (i + 1));
		// Swap elements at indices i and j
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}


export function getCookie(name) {
	const cookies = document.cookie.split(";");
	for (const cookie of cookies) {
		const [cookieName, cookieValue] = cookie.split("=");
		if (cookieName.trim() === name) {
			return decodeURIComponent(cookieValue);
		}
	}
	return null;
}

export const checkCookieConsent = () => {
	const matches = Boolean(getCookie("cookieConsent"));
	return matches;
};



export const injectWatchlistStyle = async (className, time = 100) => {

	await setTimeout(() => {
		const element = document.querySelector(`.${className}`);
		const style = document.createElement("style");
		style.innerHTML = `
		.author-watchlist-information-title {
			padding-top: 24px;
			padding-bottom: 0px;
			font-size: 22px;
			font-weight: 700;
			line-height: 29px;
			letter-spacing: 0em;
			font-family: Rubik !important;
			text-align: center !important;

		}

		.author-watchlist-information-subtitle {
			padding-top: 8px 0px;
			font-size: 14px;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0em;
			text-align: center !important;
			color: #1e293b;
			font-family: Rubik !important;
			display: inline-block;
		}

		.author-watchlist-information-email-input {
			margin-bottom: 10px;
			padding: 8px;
		}

		.author-watchlist-information-subscribe-button {
			background-color: #ef6820;
			color: #fff;
			font-weight: 700;
			font-size: 16px;
			border: none;
			border-radius: 8px;
			padding: 10px;
			font-size: 16px;
			cursor: pointer;
			position: relative;
			transition: background-color 0.3s ease;
			width: 100%;
			display: block;
			margin-top: 20px;
			font-family: Rubik !important;
		}


		.author-watchlist-information-subscribe-button::after {
			font-size: 24px;
			width: 20px;
			height: 14.14px;
			top: 3px;
		}

		.author-watchlist-information-description {
			font-size: 14px;
		}

		.author-watchlist-image {
			width: 100%;
		}

		.author-watchlist-image img {
			width: 100%;
			height: auto;
			-o-object-fit: cover;
			object-fit: cover;
			border-radius: 4px;
		}


		.author-side-box {
			
			border-radius: 24px;
			background-color: white;
			display: flex;
			flex-direction: column;
			padding: 20px;
			gap: 20px;
			box-shadow: 0px 7px 20px 0px #0000001a;
			margin-left: 0;
			width: 100%;
			height: auto;
			padding: 20px;
			box-shadow: 0px 7px 20px 0px #0000001a;

		}

		@media (max-width: 768px) {
			.author-side-box {
				margin-left: 0;
				width: 100%;
				height: auto;
				padding: 20px;
				box-shadow: 0px 7px 20px 0px #0000001a;
			}
		}
		`;
		document.head.appendChild(style);
		element.style.display = "block";
	}, time);
}
